import React from 'react';
import RoutePaths from './route-paths';
import { createBrowserHistory } from 'history';
import { RouteType } from './routes.types';
import { User } from '../../redux/session/types';
import { isAvailableTellers } from '../../helpers/utils';

export const history = createBrowserHistory();

export const getRoutes = (user: User | null) => {
  const Routes: RouteType[] = [
    {
      path: RoutePaths.login,
      component: React.lazy(() => import('../../pages/session/login/login-page')),
    },
    {
      path: RoutePaths.forgotPassword,
      component: React.lazy(
        () => import('../../pages/session/forgot-password/forgot-password-page'),
      ),
    },
    {
      path: RoutePaths.home,
      isMenu: true,
      requiresAuthentication: true,
      items: {
        dashboard: {
          path: RoutePaths.dashboard,
          component: React.lazy(() => import('../../pages/dashboard/dashboard-page')),
          icon: 'home',
          title: 'nav_dashboard',
          hasPermission: true,
        },
        contacts: {
          path: RoutePaths.contacts,
          component: React.lazy(() => import('../../pages/contacts/contacts')),
          icon: 'account_box',
          title: 'nav_contacts',
          hasPermission: true,
        },
        sendMoney: {
          path: RoutePaths.sendMoney,
          component: React.lazy(() => import('../../pages/send-money/send-money')),
          icon: 'send_money',
          title: 'nav_send_money',
          hasPermission: true,
        },
        cardTransactions: {
          path: RoutePaths.cardTransactions,
          component: React.lazy(() => import('../../pages/card-transactions/card-transactions')),
          icon: 'request_quote',
          title: 'label_issuance',
          hasPermission: true,
        },
        cardTransactionsDetails: {
          path: RoutePaths.cardTransactionsDetail,
          component: React.lazy(
            () => import('../../pages/card-transactions/card-transaction-detail'),
          ),
          icon: 'request_quote',
          title: 'label_issuance',
          showOnMenu: false,
          hasPermission: true,
        },
        cardTransactionsFromGroup: {
          path: RoutePaths.cardTransactionsFromGroup,
          component: React.lazy(
            () => import('../../pages/card-transactions/issuance-cards-from-group'),
          ),
          icon: 'request_quote',
          title: 'label_issuance',
          showOnMenu: false,
          hasPermission: true,
        },
        transfer: {
          path: RoutePaths.transfer,
          component: React.lazy(() => import('../../pages/balance/transfer')),
          icon: 'swap_vertical_circle',
          title: 'transfer_balance',
          showOnMenu: false,
          hasPermission: user?.balanceEnabled && user?.transferBalanceEnabled,
        },
        cashout: {
          path: RoutePaths.cashout,
          component: React.lazy(() => import('../../pages/balance/cashout')),
          icon: 'share-square',
          title: 'Cashout',
          showOnMenu: false,
          hasPermission: user?.balanceEnabled && user?.cashOutEnabled,
        },
        cashoutHistory: {
          path: RoutePaths.cashoutHistory,
          component: React.lazy(() => import('../../pages/balance/cashout-history')),
          icon: 'share-square',
          title: 'nav_cashout_history',
          showOnMenu: false,
          hasPermission: user?.balanceEnabled && user?.cashOutEnabled,
        },
        addBalance: {
          path: RoutePaths.addBalance,
          component: React.lazy(() => import('../../pages/balance/add-balance')),
          icon: '',
          title: 'add_balance',
          showOnMenu: false,
          hasPermission: user?.balanceEnabled && user?.addFoundEnabled,
        },
        subtractBalance: {
          path: RoutePaths.subtractBalance,
          component: React.lazy(() => import('../../pages/balance/subtract')),
          icon: 'wallet',
          title: 'subtract_balance',
          showOnMenu: false,
          hasPermission: user?.subtractBalanceEnabled,
        },
        transferHistory: {
          path: RoutePaths.transferHistory,
          component: React.lazy(() => import('../../pages/balance/transfer-history')),
          icon: 'share-square',
          title: 'transfer_history',
          showOnMenu: false,
          hasPermission: user?.balanceEnabled,
        },
        transferConfiguration: {
          path: RoutePaths.transferConfiguration,
          component: React.lazy(() => import('../../pages/balance/transfer-configuration')),
          icon: 'share-square',
          title: 'transfer_configuration',
          showOnMenu: false,
          hasPermission: user?.balanceEnabled && user?.balanceConfigurationEnabled,
        },
        commissions: {
          path: RoutePaths.commissions,
          component: React.lazy(() => import('../../pages/commissions')),
          icon: 'money_bag',
          title: 'nav_commissions',
          hasPermission: user?.viewResellerDepositCommissionHistory,
        },
        cashInPage: {
          path: RoutePaths.cashInPage,
          component: React.lazy(() => import('../../pages/cashin/cashin-card')),
          icon: 'checkbook',
          title: 'cashin_page',
          showOnMenu: false,
          hasPermission: user?.fundsCreationEnabled,
        },
        cashInHistory: {
          path: RoutePaths.cashInHistory,
          component: React.lazy(() => import('../../pages/cashin/cashin-history')),
          icon: 'list_alt',
          title: 'cashin_history',
          showOnMenu: false,
          hasPermission: user?.fundsCreationEnabled,
        },
        cashInRequests: {
          path: RoutePaths.cashInRequests,
          component: React.lazy(() => import('../../pages/cashin/cashin-request')),
          icon: '',
          title: 'nav_cashin_requests',
          showOnMenu: false,
          hasPermission: user?.manageRequestCashIn,
        },
        oneTouch: {
          path: RoutePaths.transfer,
          component: React.lazy(() => import('../../pages/balance/transfer')),
          icon: 'account_balance_wallet',
          title: 'label_balance',
          hasPermission: user?.balanceEnabled,
          subMenuItems: [
            {
              path: RoutePaths.addBalance,
              component: React.lazy(() => import('../../pages/balance/add-balance')),
              icon: '',
              title: 'add_balance',
              hasPermission: user?.balanceEnabled && user?.addFoundEnabled,
            },
            {
              path: RoutePaths.subtractBalance,
              component: React.lazy(() => import('../../pages/balance/subtract')),
              icon: 'wallet',
              title: 'subtract_balance',
              hasPermission: user?.subtractBalanceEnabled,
            },
            {
              path: RoutePaths.transfer,
              component: React.lazy(() => import('../../pages/balance/transfer')),
              icon: '',
              title: 'transfer_balance',
              hasPermission: user?.balanceEnabled && user?.transferBalanceEnabled,
            },
            {
              path: RoutePaths.transferHistory,
              component: React.lazy(() => import('../../pages/balance/transfer-history')),
              icon: '',
              title: 'transfer_history',
              hasPermission: user?.balanceEnabled && user?.transferBalanceEnabled,
            },
            {
              path: RoutePaths.transferConfiguration,
              component: React.lazy(() => import('../../pages/balance/transfer-configuration')),
              icon: '',
              title: 'transfer_configuration',
              hasPermission: user?.balanceEnabled && user?.balanceConfigurationEnabled,
            },
            {
              path: RoutePaths.cashout,
              component: React.lazy(() => import('../../pages/balance/cashout')),
              icon: '',
              title: 'nav_cashout',
              hasPermission: user?.balanceEnabled && user?.cashOutEnabled,
            },
            {
              path: RoutePaths.cashoutHistory,
              component: React.lazy(() => import('../../pages/balance/cashout-history')),
              icon: '',
              title: 'nav_cashout_history',
              hasPermission: user?.balanceEnabled && user?.cashOutEnabled,
            },
          ],
        },
        oneTouchCashIn: {
          path: RoutePaths.transfer,
          component: React.lazy(() => import('../../pages/balance/transfer')),
          icon: 'checkbook',
          title: 'label_cashin',
          hasPermission: user?.fundsCreationEnabled,
          subMenuItems: [
            {
              path: RoutePaths.cashInPage,
              component: React.lazy(() => import('../../pages/cashin/cashin-card')),
              icon: '',
              title: 'cashin_card',
              hasPermission: user?.fundsCreationEnabled,
            },
            {
              path: RoutePaths.cashInHistory,
              component: React.lazy(() => import('../../pages/cashin/cashin-history')),
              icon: '',
              title: 'cashin_history',
              hasPermission: user?.fundsCreationEnabled,
            },
            {
              path: RoutePaths.cashInRequests,
              component: React.lazy(() => import('../../pages/cashin/cashin-request')),
              icon: '',
              title: 'nav_cashin_requests',
              hasPermission: user?.manageRequestCashIn,
            },
          ],
        },
        resellers: {
          path: RoutePaths.resellers,
          component: React.lazy(() => import('../../pages/resellers/resellers-page')),
          icon: 'group',
          title: 'nav_resellers',
          hasPermission: user?.resellerCreationEnabled,
        },
        resellersEdit: {
          path: RoutePaths.resellersEdit,
          component: React.lazy(() => import('../../pages/editResellers/edit-reseller-page')),
          icon: 'user-edit',
          title: 'label_edit_reseller',
          hasPermission: user?.resellerCreationEnabled,
          showOnMenu: false,
        },
        resellersDetail: {
          path: RoutePaths.resellersDetail,
          component: React.lazy(() => import('../../pages/resellers/reseller-details-page')),
          icon: 'group',
          title: 'nav_resellers',
          hasPermission: user?.resellerCreationEnabled,
          showOnMenu: false,
        },
        resellersSpreadDetail: {
          path: RoutePaths.resellersSpreadDetail,
          component: React.lazy(() => import('../../pages/resellers/resellers-spread-page')),
          icon: 'group',
          title: 'nav_resellers',
          hasPermission: user?.resellerCreationEnabled,
          showOnMenu: false,
        },
        getSubResellers: {
          path: RoutePaths.subResellers,
          component: React.lazy(() => import('../../pages/resellers/sub-resellers-page')),
          icon: 'group',
          title: 'nav_resellers',
          hasPermission: true,
          showOnMenu: false,
        },
        requests: {
          path: RoutePaths.requests,
          component: React.lazy(() => import('../../pages/requests/request-page')),
          icon: 'sync_lock',
          title: 'label_requests',
          hasPermission: user?.requestsEnabled,
        },
        requestsDetails: {
          path: RoutePaths.requestDetail,
          component: React.lazy(() => import('../../pages/requests/request-details-page')),
          icon: 'user-friends',
          title: 'requests_details',
          hasPermission: user?.requestsEnabled,
          showOnMenu: false,
        },
        notifications: {
          path: RoutePaths.notifications,
          component: React.lazy(() => import('../../pages/notifications/notifications')),
          icon: 'notifications_active',
          title: 'nav_notifications',
          hasPermission: true,
          showOnMenu: false,
        },
        notificationsCreate: {
          path: RoutePaths.notificationsCreate,
          component: React.lazy(() => import('../../pages/notifications/notification-create')),
          icon: 'notifications_active',
          title: 'label_notifications',
          hasPermission: user?.manageNotificationEnabled,
          showOnMenu: true,
        },
        compensationHistory: {
          path: RoutePaths.compensationHistory,
          component: React.lazy(() => import('../../pages/compensation/compensation-page')),
          icon: 'checkbook',
          title: 'label_compensations',
          hasPermission: user?.manageCompensation,
          showOnMenu: false,
        },
        compensationCreate: {
          path: RoutePaths.compensationForm,
          component: React.lazy(() => import('../../pages/compensation/compensation-create')),
          icon: 'plus',
          title: 'label_compensation',
          hasPermission: user?.manageCompensation,
          showOnMenu: false,
        },
        compensationBitstamp: {
          path: RoutePaths.compensationBitstamp,
          component: React.lazy(() => import('../../pages/compensation/compensation-bitstamp')),
          icon: 'currency_bitcoin',
          title: 'nav_bitstamp',
          hasPermission: user?.manageCompensation,
          showOnMenu: false,
        },
        permissions: {
          path: RoutePaths.permissions,
          component: React.lazy(() => import('../../pages/permissions/permissions-page')),
          icon: 'verified_user',
          title: 'nav_permissions',
          hasPermission: user?.manageFeatureReseller,
        },
        bonusBox: {
          path: RoutePaths.bonusBox,
          component: React.lazy(() => import('../../pages/bonus-box/bonus-box-page')),
          icon: 'star',
          title: 'nav_bonus_box',
          hasPermission: true,
          showOnMenu: false,
        },
        bonusBoxOrders: {
          path: RoutePaths.bonusBoxOrders,
          component: React.lazy(() => import('../../pages/bonus-box/bonus-box-order-history-page')),
          icon: 'list_alt',
          title: 'nav_orders_history',
          hasPermission: true,
          showOnMenu: false,
        },
        bonusBoxGroup: {
          path: RoutePaths.bonusBox,
          component: React.lazy(() => import('../../pages/bonus-box/bonus-box-page')),
          icon: 'redeem',
          title: 'nav_bonus_box',
          hasPermission: true,
          subMenuItems: [
            {
              path: RoutePaths.bonusBox,
              component: React.lazy(() => import('../../pages/bonus-box/bonus-box-page')),
              icon: '',
              title: 'nav_purchase',
              hasPermission: true,
            },
            {
              path: RoutePaths.bonusBoxOrders,
              component: React.lazy(
                () => import('../../pages/bonus-box/bonus-box-order-history-page'),
              ),
              icon: '',
              title: 'nav_orders_history',
              hasPermission: true,
            },
          ],
        },
        compensation: {
          path: RoutePaths.compensationHistory,
          component: React.lazy(() => import('../../pages/compensation/compensation-page')),
          icon: 'mintmark',
          title: 'label_compensations',
          hasPermission: user?.manageCompensation,
          subMenuItems: [
            {
              path: RoutePaths.compensationHistory,
              component: React.lazy(() => import('../../pages/compensation/compensation-page')),
              icon: '',
              title: 'nav_compensation_history',
              hasPermission: user?.manageCompensation,
            },
            {
              path: RoutePaths.compensationBitstamp,
              component: React.lazy(() => import('../../pages/compensation/compensation-bitstamp')),
              icon: '',
              title: 'nav_bitstamp',
              hasPermission: user?.manageCompensation,
            },
          ],
        },
        logs: {
          path: RoutePaths.logs,
          component: React.lazy(() => import('../../pages/logs/logs-page')),
          icon: 'input',
          title: 'nav_logs',
          hasPermission: user?.watchLogs,
          showOnMenu: true,
        },
        movements: {
          path: RoutePaths.movements,
          component: React.lazy(() => import('../../pages/logs/movements-logs-page')),
          icon: '',
          title: 'nav_movements',
          hasPermission: user?.watchResellerMovements,
          showOnMenu: true,
        },
        ...(isAvailableTellers
          ? {
              tellerOrders: {
                path: RoutePaths.tellerOrders ? RoutePaths.tellerOrders : '',
                component: React.lazy(() => import('../../pages/teller-orders/teller-orders')),
                icon: 'orders',
                title: 'label_teller_orders',
                hasPermission: user?.tellerEnabled,
                showOnMenu: true,
              },
              tellerOrderDetail: {
                path: RoutePaths.tellerOrderDetail ? RoutePaths.tellerOrderDetail : '',
                component: React.lazy(
                  () => import('../../pages/teller-orders/teller-orders-detail'),
                ),
                icon: 'orders',
                title: 'label_teller_order_detail',
                hasPermission: user?.tellerEnabled,
                showOnMenu: false,
              },
            }
          : {}),
      },
    },
  ];

  return Routes;
};
