import { isAvailableTellers } from '../../helpers/utils';

const RoutePaths = {
  home: '/',
  dashboard: '/dashboard',
  login: '/auth/:step',
  forgotPassword: '/recovery/password/:step',
  paymentMethodslist: '/payment_methods',
  contacts: '/contacts',
  sendMoney: '/send-money',
  cardTransactions: '/card-transactions',
  addBalance: '/add-balance',
  subtractBalance: '/subtract-balance',
  cashout: '/cashout',
  cashoutHistory: '/cashout-history',
  cashInPage: '/cashin-card',
  cashInHistory: '/cashin-history',
  cashInRequests: '/cashin-requests',
  commissions: '/commissions',
  cardTransactionsFromGroup: '/card-transactions/:groupId',
  cardTransactionsDetail: '/card-transactions-detail/:cardTransactionsId',
  transfer: '/transfer',
  transferHistory: '/transfer-history',
  transferConfiguration: '/transfer-configuration',
  resellers: '/resellers',
  resellersDetail: '/resellers/:resellerId',
  resellersEdit: '/resellers/:resellerId/edit',
  resellersSpreadDetail: '/resellers/:resellerId/spreads',
  resellersIps: '/resellers/:resellerId/ips-config',
  subResellers: '/resellers/:resellerId/sub-list',
  requests: '/requests',
  requestDetail: '/requests/:requestId',
  notifications: '/notifications',
  notificationsCreate: '/notifications/create',
  compensationHistory: '/compensations',
  compensationForm: '/compensation-create',
  permissions: '/permissions',
  bonusBox: '/bonus-box',
  bonusBoxOrders: '/bonus-box-orders',
  logs: '/logs',
  movements: '/movements',
  compensationBitstamp: '/compensation-bitstamp',
  ...(isAvailableTellers && {
    tellerOrders: '/teller/orders',
    tellerOrderDetail: '/teller/orders/:orderId',
  }),
};

export default RoutePaths;
