import { ObjectOf } from '../types/global';
import { resources } from '../config/constants/urls';

const buildPathWithParams = (path: string, params: ObjectOf<string>) => {
  let finalPath = path;
  Object.keys(params).forEach((key) => {
    finalPath = finalPath.replace(`:${key}`, params[key]);
  });
  return finalPath;
};

const getQueryParam = (key: string): string | null => {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(key);
  } catch {
    return null;
  }
};

const buildPathStringParams = (path: string, params: string) => {
  const optionalParams = new URLSearchParams(params);
  const finalPath = path + '?' + optionalParams.toString();
  return finalPath;
};

const getMerchantImage = (merchantImage: string) =>
  merchantImage ? `${resources.merchants}${merchantImage}.svg` : defaultImg;

const getCountryImage = (countryCode: string) =>
  countryCode ? `${resources.flags}${countryCode.toUpperCase()}.svg` : defaultImg;

const getTellerPaymentMethodImage = (paymentMethodCode: string) =>
  paymentMethodCode
    ? `${resources.tellerPaymentMethods}${paymentMethodCode.toUpperCase()}.png`
    : defaultImg;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleErrorImage = (e: any) => {
  e.target.src = defaultImg;
};

const defaultImg = '';
// const defaultImg = `${process.env.PUBLIC_URL}/img/default-image.svg`;

export {
  buildPathWithParams,
  getQueryParam,
  getMerchantImage,
  getCountryImage,
  getTellerPaymentMethodImage,
  handleErrorImage,
  buildPathStringParams,
};
