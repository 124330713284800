import { createSelector } from 'reselect';
import moment from 'moment';
import {
  AssignedIssuanceFilterState,
  BonusBoxFilterState,
  CashoutFilterState,
  CommissionsFilterState,
  CompensationFilterState,
  FilterState,
  GrupedIssuanceFilterState,
  LogsFilterState,
  MovementLogsFilterState,
  PaymentsFilterState,
  RequestFilterState,
  ResellerFilterState,
  TellerOrdersFilterState,
  TellersFilterState,
} from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const initialState: FilterState = {
  requests: {
    status: 'all',
    dateCreationTo: moment().format('YYYY-MM-DD'),
    dateCreationFrom: moment().subtract(1, 'years').format('YYYY-MM-DD'),
    email: '',
  },
  cashinRequests: {
    status: 'all',
    dateCreationTo: moment().format('YYYY-MM-DD'),
    dateCreationFrom: moment().subtract(1, 'years').format('YYYY-MM-DD'),
  },
  assignedIssuance: {
    status: 'all',
    dateCreationTo: moment().format('YYYY-MM-DD'),
    dateCreationFrom: moment().subtract(1, 'years').format('YYYY-MM-DD'),
  },
  grupedIssuance: {
    voucherOption: 'all',
    voucherValue: '',
    dateCreationTo: moment().format('YYYY-MM-DD'),
    dateCreationFrom: moment().subtract(1, 'years').format('YYYY-MM-DD'),
  },
  compensation: {
    dateCreationTo: moment().format('YYYY-MM-DD'),
    dateCreationFrom: moment().subtract(1, 'years').format('YYYY-MM-DD'),
    email: '',
  },
  tellers: {
    email: '',
  },
  tellerOrders: {
    status: 'ALL',
  },
  bonusBox: {
    dateCreationTo: moment().format('YYYY-MM-DD'),
    dateCreationFrom: moment().subtract(1, 'years').format('YYYY-MM-DD'),
  },
  forceReloadByFilter: false,
  logs: {
    dateCreationTo: moment().format('YYYY-MM-DD'),
    dateCreationFrom: moment().subtract(1, 'years').format('YYYY-MM-DD'),
    email: '',
  },
  movementlogs: {
    dateCreationTo: moment().format('YYYY-MM-DD'),
    dateCreationFrom: moment().subtract(1, 'years').format('YYYY-MM-DD'),
    email: '',
    movements: [],
  },
  payments: {
    id: '',
    status: 'all',
    dateCreationTo: moment().format('YYYY-MM-DD'),
    dateCreationFrom: moment().subtract(1, 'years').format('YYYY-MM-DD'),
  },
  cashout: {
    id: '',
    wallet: '',
    status: 'all',
    toDate: moment().format('YYYY-MM-DD'),
    fromDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
  },
  reseller: {
    searchValue: '',
    country: '',
    type: '',
  },
  commissions: {
    dateFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
  },
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setRequestFilter: (state, action: PayloadAction<RequestFilterState>) => {
      state.requests = action.payload;
    },
    resetRequestFilter: (state) => {
      state.requests = initialState.requests;
    },
    setCashinRequestFilter: (state, action: PayloadAction<RequestFilterState>) => {
      state.cashinRequests = action.payload;
    },
    resetCashinRequestFilter: (state) => {
      state.cashinRequests = initialState.cashinRequests;
    },
    setAssignedIssuanceFilter: (state, action: PayloadAction<AssignedIssuanceFilterState>) => {
      state.assignedIssuance = action.payload;
    },
    resetAssignedIssuanceFilter: (state) => {
      state.assignedIssuance = initialState.assignedIssuance;
    },
    setGrupedIssuanceFilter: (state, action: PayloadAction<GrupedIssuanceFilterState>) => {
      state.grupedIssuance = action.payload;
    },
    resetGrupedIssuanceFilter: (state) => {
      state.grupedIssuance = initialState.grupedIssuance;
    },
    setCompensationFilter: (state, action: PayloadAction<CompensationFilterState>) => {
      state.compensation = action.payload;
    },
    resetCompensationFilter: (state) => {
      state.compensation = initialState.compensation;
    },
    setTellersFilter: (state, action: PayloadAction<TellersFilterState>) => {
      state.tellers = action.payload;
    },
    resetTellersFilter: (state) => {
      state.tellers = initialState.tellers;
    },
    setTellerOrdersFilter: (state, action: PayloadAction<TellerOrdersFilterState>) => {
      state.tellerOrders = action.payload;
    },
    resetTellerOrdersFilter: (state) => {
      state.tellerOrders = initialState.tellerOrders;
    },
    setBonusBoxFilter: (state, action: PayloadAction<BonusBoxFilterState>) => {
      state.bonusBox = action.payload;
    },
    resetBonusBoxFilter: (state) => {
      state.bonusBox = initialState.bonusBox;
    },
    setForceReloadByFilter: (state, action: PayloadAction<boolean>) => {
      state.forceReloadByFilter = action.payload;
    },
    setLogsFilter: (state, action: PayloadAction<LogsFilterState>) => {
      state.logs = action.payload;
    },
    resetLogsFilter: (state) => {
      state.logs = initialState.logs;
    },
    setMovementLogsFilter: (state, action: PayloadAction<MovementLogsFilterState>) => {
      state.movementlogs = action.payload;
    },
    resetMovementLogsFilter: (state) => {
      state.movementlogs = initialState.movementlogs;
    },
    setPaymentsFilter: (state, action: PayloadAction<PaymentsFilterState>) => {
      state.payments = action.payload;
    },
    resetPaymentsFilter: (state) => {
      state.payments = initialState.payments;
    },
    setCashoutFilter: (state, action: PayloadAction<CashoutFilterState>) => {
      state.cashout = action.payload;
    },
    resetCashoutFilter: (state) => {
      state.cashout = initialState.cashout;
    },
    setResellerFilter: (state, action: PayloadAction<ResellerFilterState>) => {
      state.reseller = action.payload;
    },
    resetResellerFilter: (state) => {
      state.reseller = initialState.reseller;
    },
    setCommissionsFilter: (state, action: PayloadAction<CommissionsFilterState>) => {
      state.commissions = action.payload;
    },
    resetCommissionsFilter: (state) => {
      state.commissions = initialState.commissions;
    },
  },
});

// Selectors
const getFilter = (state: RootState): FilterState => state.filters;
export const getRequestFilter = createSelector(getFilter, (filters) => filters.requests);
export const getCashinRequestFilter = createSelector(
  getFilter,
  (filters) => filters.cashinRequests,
);
export const getAssignedIssuanceFilter = createSelector(
  getFilter,
  (filters) => filters.assignedIssuance,
);
export const getGrupedIssuanceFilter = createSelector(
  getFilter,
  (filters) => filters.grupedIssuance,
);
export const getCompensationFilter = createSelector(getFilter, (filters) => filters.compensation);
export const getTellersFilter = createSelector(getFilter, (filters) => filters.tellers);
export const getTellerOrderFilters = createSelector(getFilter, (filters) => filters.tellerOrders);
export const getBonusBoxFilter = createSelector(getFilter, (filters) => filters.bonusBox);
export const getForceReloadByFilter = createSelector(
  getFilter,
  (filters) => filters.forceReloadByFilter,
);
export const getLogsFilter = createSelector(getFilter, (filters) => filters.logs);
export const getMovementLogsFilter = createSelector(getFilter, (filters) => filters.movementlogs);
export const getPaymentsFilter = createSelector(getFilter, (filters) => filters.payments);
export const getCashoutFilter = createSelector(getFilter, (filters) => filters.cashout);
export const getResellersFilter = createSelector(getFilter, (filters) => filters.reseller);
export const getCommissionsFilter = createSelector(getFilter, (filters) => filters.commissions);
const { actions, reducer } = filtersSlice;

export const {
  setRequestFilter,
  resetRequestFilter,
  setCashinRequestFilter,
  resetCashinRequestFilter,
  setAssignedIssuanceFilter,
  setGrupedIssuanceFilter,
  resetAssignedIssuanceFilter,
  resetGrupedIssuanceFilter,
  setCompensationFilter,
  resetCompensationFilter,
  setTellersFilter,
  resetTellersFilter,
  setTellerOrdersFilter,
  resetTellerOrdersFilter,
  setBonusBoxFilter,
  resetBonusBoxFilter,
  setForceReloadByFilter,
  setLogsFilter,
  resetLogsFilter,
  setMovementLogsFilter,
  resetMovementLogsFilter,
  setPaymentsFilter,
  resetPaymentsFilter,
  setCashoutFilter,
  resetCashoutFilter,
  setResellerFilter,
  resetResellerFilter,
  setCommissionsFilter,
  resetCommissionsFilter,
} = actions;

export default reducer;
